import React, { useEffect } from "react";
import logo from "../Assets/Logo/logo.webp";
import arrow_popup from "../Assets/arrow_popup.gif";
import ReactPlayer from "react-player";

const Thankyou = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    window.fbq("track", "Lead");
  }, []);

  return (
    <>
      <div className="container">
        <section className="mt-5">
          <div className="alignment">
            <img src={logo} alt="Social Social" className="logo" />
          </div>
        </section>
        <section className="mt-5">
          <div className="alignment">
            <h6 className="thankstitle">"CONGRATULATIONS"</h6>
            <p className="thankstitle1">
              You are an&nbsp;
              <span className="freecall">ACTION TAKER</span>
              <br />
              <span className="thankssubtitle">(Fortune Favours The Bold)</span>
            </p>
            <p className="thankstitle1">
              You Are One Step Away From Changing Your Life Forever
            </p>
            <div className="my-5">
              <ReactPlayer
                url="https://socialeagle.wistia.com/medias/jlxwqvhbo1"
                className="videoalignment"
              />
            </div>
          </div>
          <div className="d-flex flex-lg-row flex-column gap-lg-5 justify-content-center">
            <a href="https://socialeagleacademy.com/#/Results" target="_blank" className="thank_button_center">
              <button className="thank_button">
              <img src={arrow_popup} alt="Social Social" className="gif_image me-3" />
                Explore Students Achievements
              </button>
            
            </a>
            <a
              href="https://chat.whatsapp.com/F74R9iJvQm5JMDlY4Rjv9D"
              target="_blank"
              className="thank_button_center"
            >
               
              <button className="thank_button ">
              <img src={arrow_popup} alt="Social Social" className="gif_image me-3" />
                Join Our Free Community
              </button>
             
            </a>
          </div>
        </section>
      </div>
    </>
  );
};

export default Thankyou;
