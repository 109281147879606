import React, { useEffect, useState } from "react";
import Header from "./Header";
import CustomModal from "../CustomModal";
import { useNavigate } from "react-router-dom/dist";
import bannerimage from "../Assets/Bannerimage.jfif";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Footer from "./Footer";
import star from "../Assets/star.webp";
import trust from "../Assets/trust.webp";
import googleStar from "../Assets/googleStar.webp";
import google from "../Assets/google.webp";
import axios from "axios";
import DupModal from "../DupModal";

const Optin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    window.fbq("track", "Lead");
  }, []);

  const [addModel, setAddModel] = useState(false);
  const [dupModel, setdupModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [create, SetCreate] = useState({});
  const [validation, setValidation] = useState({});

  const CheckValidation = () => {
    let Status = true,
      validdateData = {};
    if (!create.Name) {
      validdateData.Name = "Name is Required";
      Status = false;
    }
    if (!create.Email) {
      validdateData.Email = "Email is Required";
      Status = false;
    }
    if (!create.Number) {
      validdateData.Number = "Number is Required";
      Status = false;
    } else if (create.Number.length < 10) {
      validdateData.Number = "Invalid Number";
      Status = false;
    }
    if (!create.Location) {
      validdateData.Location = "Location is Required";
      Status = false;
    }
    setValidation({ ...validation, ...validdateData });
    return Status;
  };

  const setData = (e, key) => {
    SetCreate({ ...create, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const Payload = [
      {
        Attribute: "FirstName",
        Value: create.Name,
      },
      {
        Attribute: "EmailAddress",
        Value: create.Email,
      },
      {
        Attribute: "Phone",
        Value: create.Number,
      },
      {
        Attribute: "mx_City",
        Value: create.Location,
      },
      {
        Attribute: "Source",
        Value: "Optin",
      },
    ];

    if (CheckValidation()) {
      try {
        const response = await axios.post(
          "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r6bb21b3cf1518343d1981ce6646b844f&secretKey=d58ccce3a29496917e48f84d2f40d31061c6b781",
          Payload,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.Status === "Error") {
          alert("check")
        } else if (response.data.Status === "Success") {
          setLoading(false);
          setAddModel(false);
          setTimeout(() => {
            if (response.data.Status === "Success") {
              navigate("./Masterclass");
            } else {
              navigate("/");
            }
          }, 500);
        } else {
          setLoading(false);
          setButtonDisabled(true);
        }
      } catch (e) {
        setLoading(false);
        setButtonDisabled(false);
        // setdupModel(true);
        navigate("./Masterclass");
        console.log(e, "error")

      }

    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <section>
        <div className="mt-md-5">
          <h1 className="title">
            Learn How to Scale&nbsp;
            <span className="parahighlight">Your Business </span>Digitally or Become a
            <br />
            <span className="parahighlight">Digital Entrepreneur!</span>
            <h1 className="subsubtitle pt-3">
              (With Zero Expertise & Zero Experience)
            </h1>
          </h1>
        </div>
      </section>
      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-6">
              <img
                src={bannerimage}
                alt="Social Eagle"
                className="bannerimage"
                onClick={() => setAddModel(true)}
              />
            </div>
            <div className="col-lg-6">
              <p className="para col_head">
                <span className="parahighlight"> For Aspiring Entrepreneurs and Business Owners </span>Looking to Scale:
              </p>
              <p className="subpara">
                <span className="line">
                  <span className="icon">✔ </span>
                  Achieve the freedom of time, travel, and money while growing your side hustle or business.
                </span>
                <br />
                <span className="line">
                  <span className="icon">✔ </span>
                  Scale your existing business to new heights.
                </span>
                <br />
                <span className="line">
                  <span className="icon">✔ </span>
                  No prior expertise or experience needed.
                </span>
                <br />
                <span className="line">
                  <span className="icon">✔ </span>
                  Escape the limitations of your current job (9-5) or business model.
                </span>
                <br />
                <span className="line">
                  <span className="icon">✔ </span>
                  Understand the possibilities of earning 50K -100K INR and even more by doing side hustle.
                </span>
              </p>

              <div className="alignment">
                <button className="button" onClick={() => setAddModel(true)} >
                  Watch Now For FREE
                </button>
                <div className="row">
                  <div className="col-md-8 mx-auto ms-auto">
                    <div className="row mt-3 align-items-center gy-lg-2 gy-5">
                      <div className="col-md-3 col-6 p-0">
                        <div className="alignment">
                          <img src={star} alt="Star" className="starimage" />
                        </div>
                      </div>
                      <div className="col-md-3 col-6 p-0">
                        <img src={trust} alt="Star" className="trustimage" />
                      </div>
                      <div className="col-md-3 col-6 p-0">
                        <div className="alignment">
                          <img src={googleStar} alt="Star" className="starimage" />
                        </div>
                      </div>
                      <div className="col-md-3 col-6 p-0">
                        <img src={google} alt="Star" className="trustimage" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  addModel */}
      <CustomModal
        open={addModel}
        onClickOutside={() => {
          setAddModel(false);
        }}
      >
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="modal-content  m-auto">
            <div className=" text-center mt-5">
              <h6 className="titlemodel">Watch Now For FREE!</h6>
              <p className="paramodel">
                Enter your info, start watching the&nbsp;
                <span className="parahighlighttext">MASTERCLASS</span>&nbsp;
                immediately
              </p>
            </div>
            <div className="modal-body p-0 ">
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="container">
                    <div className="mt-4">
                      <input
                        type="text"
                        className="inputbox"
                        placeholder="Enter Your Full Name*"
                        defaultValue={create.Name}
                        onChange={(e) => {
                          setData(e.target.value, "Name");
                        }}
                      />
                      {validation.Name && (
                        <p className="validationmsg">{validation.Name}</p>
                      )}
                    </div>
                    <div className="mt-4">
                      <PhoneInput
                        country={"in"}
                        placeholder="Enter Your Mobile Number"
                        autoComplete="off"
                        onChange={(e) => {
                          setData(e, "Number");
                        }}
                        defaultValue={create.Number}
                      />
                      {validation.Number && (
                        <p className="validationmsg">{validation.Number}</p>
                      )}
                    </div>
                    <div className="mt-4">
                      <input
                        type="text"
                        className="inputbox"
                        placeholder="Enter Your Email Address*"
                        defaultValue={create.Email}
                        onChange={(e) => {
                          setData(e.target.value, "Email");
                        }}
                      />
                      {validation.Email && (
                        <p className="validationmsg">{validation.Email}</p>
                      )}
                    </div>
                    <div className="mt-4">
                      <input
                        type="text"
                        className="inputbox"
                        placeholder="Enter Your City*"
                        defaultValue={create.Location}
                        onChange={(e) => {
                          setData(e.target.value, "Location");
                        }}
                      />
                      {validation.Location && (
                        <p className="validationmsg">{validation.Location}</p>
                      )}
                    </div>
                    <button
                      type="submit"
                      className="modelbutton"
                      disabled={buttonDisabled || loading}
                    >
                      Watch Now For FREE!
                      {loading ? (
                        <i className="fa fa-circle-o-notch fa-spin"></i>
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  type="button"
                  className="btn mx-2 submit-btn"
                  onClick={() => {
                    setAddModel(false);
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </form>
      </CustomModal>
      <DupModal
        open={dupModel}
        onClickOutside={() => {
          setdupModel(false);
        }}
      >
        <div className="modal-content  m-auto">
          <div className=" text-center mt-5">
            <h6 className="titlemodel">Hi Again !!!</h6>
            <p className="paramodel">
              We have found that your contact information already available in our system.
            </p>
          </div>
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container">
                  <button
                    type="submit"
                    className="modelbutton"
                    onClick={() => {
                      setdupModel(false);
                    }}
                  >
                    Okay!!! Sure
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DupModal>
      <Footer />
    </>
  );
};

export default Optin;
